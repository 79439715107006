<template>
  <div class="unpaid-main">
    <div class="heard-box bgd-image">
      <div class="orderNum font24 fontCol9">订单编号：{{orderCode}}</div>
    </div>
    <div class="order-info-box">
      <!-- <div class="card-box">
				<div class="card-tit font36 fontCol3 fontBold">投保人信息</div>
				<div class="info-box">
					<div class="info-item">
						<div class="info-label font30 fontCol3">{{proposer.name}}</div>
						<div class="divide"></div>
						<div class="info-val font30 fontCol3">{{proposer.identityCode | IDCardFormat}}</div>
					</div>
				</div>
			</div> -->
      <div class="card-box scroll">
        <div class="card-tit font36 fontCol3 fontBold">投保人信息</div>
        <div class="info-box">
          <div class="info-item">
            <div class="info-label font30 fontCol3">{{proposer.name}}</div>
            <div class="divide"></div>
            <div class="info-val font30 fontCol3">{{proposer.identityCode}}</div>
          </div>
        </div>
        <div class="card-tit font36 fontCol3 fontBold marginT24">被保人信息</div>
        <div class="scroll-box">
          <div class="info-box" v-for="(it,ind) in insureds" :key="ind">
            <div class="info-item">
              <div class="info-label font30 fontCol3">{{it.name}}</div>
              <div class="divide"></div>
              <div class="info-val font30 fontCol3">{{it.identityCode}}</div>
              <div :class="['tag font24',it.relationWithProposer=='1'?'active':'']">
                {{it.relationWithProposer | relation}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-box">
        <div class="card-tit font36 fontCol3 fontBold">支付方式</div>
        <div class="payType-box">
          <van-radio-group v-model="payType">
            <van-cell-group :border="false">
              <van-cell v-for="(item, index) in payTypeList" :key="index" @click="payCheck(item)">
                <div slot="title" class="cell-tit">
                  <div class="icon"><img :src="item.icon" /></div>
                  <span class="custom-title">{{item.text}}</span>
                </div>
                <template #right-icon>
                  <van-radio :name="item.value" checked-color="#FF0904" :disabled="item.disabled" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
      </div>
    </div>
    <div class="down-time-box">
      <div class="font28 fontColMain transformCenter">剩余支付时间：</div>
      <van-count-down :time="invalidityTime" @finish="onFinish">
        <template #default="timeData">
          <span class="block" v-if="timeData.hours">{{ timeData.hours }}</span>
          <span class="colon" v-if="timeData.hours">小时</span>
          <span class="block">{{ timeData.minutes }}</span>
          <span class="colon">分</span>
          <span class="block">{{ timeData.seconds }}</span>
          <span class="colon">秒</span>
        </template>
      </van-count-down>
      <div class="font28 fontColMain transformCenter">，请尽快支付</div>
    </div>
    <div class="option-box">
      <div class="price-box">
        <div class="price-text font24">共计投保{{insureds.length}}人</div>
        <div class="price font32 fontBold">合计: {{price | price}}<span>元</span></div>
      </div>
      <div :class="['option-btn font32 fontColF',disabled||isExpire?'disabled':'']" @click="onPay">
        {{isExpire?'已结束投保':'立即支付'}}</div>
    </div>
  </div>
</template>

<script>
import Config from '@/config/config.js'
import moment from 'moment';
import { mapGetters } from "vuex";
import { recordMixin } from '@/utils/mixin/recordMixin.js'
import { relationship } from '@/utils/dictionary.js'
export default {
  name: 'Unpaid',
  mixins: [recordMixin],
  data () {
    return {
      orderId: null,
      orderCode: null,
      price: 195,
      proposer: {},
      insureds: [],
      invalidityTime: 18000000,
      disabled: false,
      isExpire: false,
      payType: 1,
      payTypeList: [
        {
          text: '银联云闪付支付',
          value: 1,
          icon: require('@/assets/images/pay/UnionPay.png'),
          disabled: false
        },
        // {
        // 	text: '微信支付',
        // 	value: 2,
        // 	icon: require('@/assets/images/pay/WeChat.png'),
        // 	disabled: true
        // }
      ]
    }
  },
  computed: {
    ...mapGetters(['endDate']),
  },
  filters: {
    IDCardFormat (val) {
      if (!val) return ''
      var reg = /(^.{6}).*(.{2})$/;
      return val.replace(reg, "$1******$2");
    },
    relation (val) {
      let i = relationship.filter(it => { return it.value == +val })
      if (i.length) return i[0].text
      return '其他'
    },
    price (val) {
      let e = +val
      if (e % 1 === 0) return e + '.00'
      return val
    }
  },
  created () {
    let that = this
    this.orderId = this.$route.query.orderId || null
    this.getOrderInfo()
    // let expire = this.endDate + ' 23:59:59'
    let expire = Config.endDateReset + ' 17:00:00'
    let n = moment().format('X');
    let e = moment(expire).format('X');
    let timer = null;
    if (+n >= +e) {
      this.isExpire = true
      that.invalidityTime = 0
    }
    else if (+n + 3600 >= +e) {
      setTimeout(() => {
        that.isExpire = true
        that.invalidityTime = 0
      }, e * 1000 - n * 1000)
    }
  },
  mounted () {
    upsdk.pluginReady(function () {
      upsdk.setTitleStyle({
        navBackgroundColor: '0xffFFFFFF',
        appletStyle: 'black', //可选，black-黑色主题，white-白色主题
        backBtnVisible: '1', // 可选，左侧返回按钮是否显示。'0'不显示，'1'显示，不传或空则默认显示
        appletTitleBarVisible: '1',// 可选，标题栏是否显示。'0'不显示，'1'显示，默认显示
      });
    });
  },
  methods: {
    onFinish () {
      this.disabled = true
    },
    getOrderInfo () {
      let params = { id: this.orderId }
      this.$API.queryUnPayOrder(params).then(res => {
        console.log('res:', res)
        let { insureds, proposer, firstYearPremium, orderCode, invalidityTime } = res.data || {}
        this.price = firstYearPremium
        this.orderCode = orderCode
        this.insureds = insureds
        this.proposer = proposer
        this.invalidityTime = this.isExpire ? 0 : invalidityTime
      }).catch(err => {
        console.log('err:', err)
      })
    },
    payCheck (item) {
      if (!item.disabled) this.payType = item.value
    },
    onPay () {
      if (this.isExpire) return this.$toast('非常抱歉，本期投保期限已过，敬请下期开放！')
      this.onRecord('Click', '立即支付')
      let that = this
      let params = {
        orderId: this.orderId,
        paymentMethod: 3, // 付款方式 1-支付宝 2-微信 3-云闪付
        platform: 1 // 支付交易平台 1-云闪付 2-支付宝 3-微信 4-保司收银台 5-银联商务
      }
      this.$API.getPayTN(params).then(res => {
        let tn = res.data.platformBillNo
        console.log('res => ', tn)
        upsdk.pay({
          tn: tn,
          success: function () {
            console.log('支付成功')
            that.onRecord('System', '支付结果', '支付成功')
            that.putRecord(that.orderId)
            that.$router.replace({ path: '/paid', query: { orderId: that.orderId } })
          },
          fail: function (err) {
            console.log('支付失败')
            that.onRecord('System', '支付结果', '支付失败')
            that.putRecord(that.orderId)
          }
        });
      }).catch(err => {
        console.log('err => ', err)
      })
    },
  }
}
</script>

<style lang="less" scoped>
.unpaid-main {
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  .heard-box {
    width: 100%;
    height: 360px;
    position: relative;
    background-image: url(../../assets/images/pay/unpaid_bj.png);
    .orderNum {
      position: absolute;
      left: 180px;
      top: 132px;
    }
  }
  .order-info-box {
    height: calc(100% - 330px);
    position: relative;
    z-index: 9;
    margin-top: -150px;
    width: 100%;
    padding: 0px 32px 32px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .card-box {
      width: 100%;
      padding: 24px;
      background-color: #ffffff;
      border-radius: 16px;
      margin-top: 24px;
      &.scroll {
        max-height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }
      &:first-child {
        margin-top: 0;
      }
      .card-tit {
        padding: 12px 8px;
      }
      .payType-box {
        width: 100%;
        /deep/ .van-cell {
          padding: 10px;
        }
        .cell-tit {
          display: flex;
          align-items: center;
          .icon {
            width: 44px;
            height: 44px;
            margin-right: 24px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .scroll-box {
        max-height: 100%;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
        scrollbar-width: none;
        -ms-overflow-style: none;
      }
      .info-box {
        padding: 24px;
        background-color: #fafafa;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;
        .info-item {
          display: flex;
          align-items: center;
          --color: 232, 126, 55;
          .divide {
            width: 2px;
            height: 28px;
            background-color: #999999;
            margin: 0 12px;
            opacity: 0.7;
          }
          .tag {
            width: 76px;
            height: 40px;
            margin-left: 12px;
            background-color: rgba(var(--color), 0.2);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(var(--color), 1);
            &.active {
              --color: 107, 151, 245;
            }
          }
        }
      }
    }
  }
  .down-time-box {
    width: 100%;
    height: 64px;
    background-color: #ffded3;
    padding: 0 36px;
    display: flex;
    align-items: center;
  }
  .option-box {
    width: 100%;
    height: 120px;
    padding: 12px 32px 12px 36px;
    background-color: #ffffff;
    display: flex;
    position: relative;
    .price-box {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .price-text {
        color: #7d8ba2;
      }
      .price {
        color: #ff5351;
        span {
          font-size: 24px;
          margin-left: 4px;
        }
      }
    }
    .option-btn {
      width: 314px;
      height: 96px;
      background-color: #fd3147;
      border-radius: 98px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.disabled {
        background-color: #cccccc;
        color: #ffffff;
        pointer-events: none;
      }
    }
    .countdown-box {
      position: absolute;
      top: -16px;
      right: 16px;
      width: 202px;
      height: 40px;
      background-image: url("~@/assets/images/pay/countdown.png");
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.colon {
  display: inline-block;
  margin: 0 4px;
  color: #fd3147;
  font-weight: 600;
}
.block {
  display: inline-block;
  font-size: 24px;
  text-align: center;
  color: #fd3147;
  font-weight: 600;
}
.flex-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.bgd-image {
  background-size: 100% 100%;
}
.transformCenter {
  transform-origin: center center;
}
.marginT24 {
  margin-top: 24px;
}
.paddingL16 {
  padding-left: 16px !important;
}
</style>
